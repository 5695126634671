import { useState, useRef, useCallback } from 'react'
import { message } from 'antd'
import { reportTimeData } from './request'
import Cookies from 'js-cookie'
import moment from 'moment'
export const secretImageUrl =
  process.env.REACT_APP_SECRETIMG ||
  'https://longan-iot.oss-cn-shenzhen.aliyuncs.com/longan-iot/students/'
export const scratchURL =
  '//' + window.location.host.replace(/^[\w]*(?=\.)/, 'make')
export const longanURL =
  '//' + window.location.host.replace(/^[\w]*(?=\.)\./, '')
export const watsonURL =
  '//' + window.location.host.replace(/^[\w]*(?=\.)/, 'watson')
export const supportURL =
  '//' + window.location.host.replace(/^[\w]*(?=\.)/, 'support')

// snap的地址
export const snapURL = window.location.host.includes('eliteu.xyz') ?
  '//snap.dev.longan.eliteu.xyz' :  
  window.location.host.includes('aimaker.space') ? 
  '//snap.aimaker.space' 
  : '//localhost:3333'
export const courseURL =
  '//longan-iot.oss-cn-shenzhen.aliyuncs.com/longan-iot/lms'

// c++的地址
export const cPlusURL = '//cpp.aimaker.space'
// 跳转详情
export const toDetail = (platform, id, rest = '') => {
  let href
  // scratch平台
  if (platform === 'scratch') {
    href = `${scratchURL}/projects/${id}/${rest}`
  // snap平台
  } else if (platform === 'snap') {
    href = `${snapURL}/projects/${id}`
  }
  return href
}

export const testPassword = (pwd) => {
  return /^[_a-zA-Z0-9]{6,16}$/.test(pwd)
}

export const testPhone = (phone) => {
  return /^1[3456789]\d{9}$/.test(phone)
}

export const testUsername = (phone) => {
  return /^[_a-zA-Z0-9]{1,20}$/.test(phone)
}

export const testName = (phone) => {
  return /^[\u4e00-\u9fa5_a-zA-Z0-9]{1,15}$/.test(phone)
}

export const testChineseString = (length, string) => {
  return new RegExp(`^[\u4e00-\u9fa5]{1,${length}}$`).test(string)
}

export const testPortfolio = (length, string) => {
  return new RegExp(`^[\u4e00-\u9fa5a-zA-Z0-9]{1,${length}}$`).test(string)
}

export const testEmail = (email) => {
  // 邮箱验证正则
  var reg = /^[A-Za-z0-9]+([_.][A-Za-z0-9]+)*@([A-Za-z0-9-]+\.)+[A-Za-z]{2,6}$/
  return reg.test(email)
}

export const testNickName = (name) => {
  // const textRegExp = /^[A-Za-z0-9_\u4e00-\u9fa5]+$/;
  const cn_arr = ['英荔', '创造乐园', '魔法学院']
  const en_arr = ['Longan', 'CodeLab', 'Aimofa', 'WAICY']
  for (let nickname of cn_arr) {
    if (name.indexOf(nickname) > -1) return false
  }
  for (let nickname of en_arr) {
    if (
      name.indexOf(nickname) > -1 ||
      name.toLowerCase().indexOf(nickname.toLowerCase()) > -1 ||
      name.toUpperCase().indexOf(nickname.toUpperCase()) > -1
    ) {
      return false
    }
  }
  return true
}

//   作者：小珥_Boy
//   链接：https://juejin.im/post/5c87a8d6e51d4570942f4f8c
//   来源：掘金
//   著作权归作者所有。商业转载请联系作者获得授权，非商业转载请注明出处。

export const getToken = () => {
  return (
    Cookies.get('token') || localStorage.getItem('token')
    // ||
    // sessionStorage.getItem('token')
  )
}

export const saveToken = (token, remember = false) => {
  // sessionStorage.setItem('token', token);

  Cookies.set('eliteuToken', token, { domain: '.eliteu.xyz', expires: 30 })

  Cookies.set('token', token, {
    domain: '.dev.longan.eliteu.xyz',
    expires: 30,
  })
  Cookies.set('token', token, {
    domain: '.test.longan.eliteu.xyz',
    expires: 30,
  })
  Cookies.set('token', token, { domain: '.longan.link', expires: 30 })
  Cookies.set('token', token, { domain: 'localhost', expires: 30 })
  Cookies.set('token', token, { domain: '.aimaker.space', expires: 30 })
  if (remember) {
    localStorage.setItem('token', token)
  }
}

export const removeToken = () => {
  Cookies.remove('eliteuToken', { domain: '.eliteu.xyz' })
  Cookies.remove('token', { domain: '.dev.longan.eliteu.xyz' })
  Cookies.remove('token', { domain: '.test.longan.eliteu.xyz' })
  Cookies.remove('token', { domain: '.longan.link' })
  Cookies.remove('token', { domain: '.aimaker.space' })
  Cookies.remove('token', { domain: 'localhost' })
  sessionStorage.removeItem('token')
  localStorage.removeItem('token')
}

export const useCutDown = (time = 60) => {
  const [coldDown, setColdDown] = useState(false)
  const [coldDownTime, setColdDownTime] = useState(time)
  const coldDownTimeRef = useRef()
  const timerRef = useRef()

  const startCutDown = useCallback(() => {
    setColdDown(true)
    setColdDownTime(time)
    timerRef.current = setInterval(() => {
      setColdDownTime((time) => {
        time = --time
        coldDownTimeRef.current = time
        return time
      })

      if (coldDownTimeRef.current === 0) {
        clearInterval(timerRef.current)
        setColdDown(false)
      }
    }, 1000)
  }, [time])

  const stopCutDown = useCallback(() => {
    timerRef.current && clearInterval(timerRef.current)
    // setColdDown(false)     login 因为路由 会先unmount 导致stopCutDown 晚于页面卸载导致warning
  }, [])

  return [coldDown, coldDownTime, startCutDown, stopCutDown]
}

export const SectionToChinese = (section) => {
  const chnNumChar = [
    '零',
    '一',
    '二',
    '三',
    '四',
    '五',
    '六',
    '七',
    '八',
    '九',
  ]
  const chnUnitChar = ['', '十', '百', '千', '万', '亿', '万亿', '亿亿']
  let strIns = '',
    chnStr = ''
  let unitPos = 0
  let zero = true
  while (section > 0) {
    var v = section % 10
    if (v === 0) {
      if (!zero) {
        zero = true
        chnStr = chnNumChar[v] + chnStr
      }
    } else {
      zero = false
      strIns = chnNumChar[v]
      strIns += chnUnitChar[unitPos]
      chnStr = strIns + chnStr
    }
    unitPos++
    section = Math.floor(section / 10)
  }
  return chnStr
}

export const numFormat = (num) => {
  if (!isNaN(num)) {
    if (num > 99999 && 10000000 > num) {
      return (
        parseFloat(num / 1000)
          .toFixed(3)
          .slice(0, -2)
          .toString() + ' k'
      )
    } else if (num >= 10000000) {
      return '10000 k'
    }
    return num
  }
  return num
}

export const imageSecret = [
  '八抓鱼.png',
  '包子.png',
  '爆米花.png',
  '变色龙.png',
  '橙子.png',
  '抽油烟机.png',
  '大象.png',
  '电脑.png',
  '狗.png',
  '海牛.png',
  '海鹞鱼.png',
  '汉堡.png',
  '河马.png',
  '核桃.png',
  '猴子.png',
  '狐狸.png',
  '浣熊.png',
  '火龙果.png',
  '甲壳虫.png',
  '监控.png',
  '巨嘴鸟.png',
  '榴莲.png',
  '猫.png',
  '猫头鹰.png',
  '猕猴桃.png',
  '奶牛.png',
  '柠檬.png',
  '牛奶.png',
  '披萨.png',
  '啤酒.png',
  '乒乓球.png',
  '企鹅.png',
  '气球.png',
  '热狗.png',
  '狮子.png',
  '寿司.png',
  '树濑.png',
  '松鼠.png',
  '桃子.png',
  '天鹅.png',
  '甜甜圈.png',
  '甜筒.png',
  '兔.png',
  '乌龟.png',
  '吸尘器.png',
  '犀牛.png',
  '洗衣机.png',
  '熊猫.png',
  '杨桃.png',
  '榨汁机.png',
  '猪.png',
]

export const mediaQuery = (query) => {
  return window.matchMedia(`(min-width: ${query}px)`).matches
}

export function deBounce(func, delay) {
  let timer
  return function (...args) {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}

export const normalizeData = (data, schema) => {
  const { id, name } = schema

  let kvObj = Object.create(null)
  let ids = []
  if (Array.isArray(data)) {
    data.forEach((item) => {
      kvObj[item[id]] = item
      ids.push(item[id])
    })
  } else {
    kvObj[data[id]] = data
    ids.push(data[id])
  }
  return {
    [name]: kvObj,
    ids,
  }
}

export const simpleToast = () => {
  let toast = null,
    timer = null
  return ({ type = 'success', delay = 3000, cb = () => {}, text }) => {
    if (timer) {
      clearTimeout(timer)
    } else {
      toast = message[type](text, 0)
    }
    timer = setTimeout(() => {
      toast()
      timer = null
      cb()
    }, delay)
  }
}

export const getFileType = (file_path) => {
  if (file_path.includes('?t=')) {
    let str = file_path.split('?t=')[0]
    return str.substring(str.lastIndexOf('.') + 1)
  } else {
    return file_path.substring(file_path.lastIndexOf('.') + 1)
  }
}

export const formatKilobit = (num) =>
  (num + '').replace(/\d{1,3}(?=(\d{3})+$)/g, '$&,')

/* 计算两个日期的相差天数（有负数)。参数格式为： 年/月/日 */
export const dateDiffer = (start, end) => {
  // 先转成毫米数，相减再转成天数
  let endMillisecond = Date.parse(end)
  let startMillisecond = Date.parse(start)

  let differMillisecond = endMillisecond - startMillisecond
  return Math.floor(differMillisecond / (24 * 3600 * 1000))
}

export const urlToJson = (url) => {
  let obj = {},
    index = url.indexOf('?'),
    params = url.substr(index + 1)

  if (index !== -1) {
    let parr = params.split('&')
    for (let i of parr) {
      let arr = i.split('=')
      obj[arr[0]] = decodeURIComponent(arr[1])
    }
  }
  return obj
}

export const reportOnLineData = (type, id) => {
  let reportTimer = null
  reportTimer && clearInterval(reportTimer)
  let startTimeStamp = new Date().getTime()
  let markType = type
  if (id) markType = `${markType}_${id}`
  reportTimer = setInterval(() => {
    let endTimeStamp = new Date().getTime()
    const lastReportTime = window.localStorage.getItem(`${markType}_report`)
    if (!lastReportTime || endTimeStamp - 60 * 1000 + 200 >= lastReportTime) {
      let data = {
        index_type: type,
        start_time: parseInt(startTimeStamp / 1000),
        end_time: parseInt(endTimeStamp / 1000),
      }
      if (id) data = { ...data, course_id: id }
      reportTimeData(data)
        .then((res) => {
          window.localStorage.setItem(`${markType}_report`, endTimeStamp)
        })
        .finally(() => {
          startTimeStamp = endTimeStamp
        })
      console.log('report', type, endTimeStamp)
    }
  }, 3 * 60 * 1000)
}

export const getDateRange = (interval) => {
  let start, end
  console.log('interval')
  if(interval === 'lastweek') {
    start = moment().day(-7).format('YYYYMMDD')
    end =  moment().day(-1).format('YYYYMMDD')
  } else if(interval === 'lastmonth') {
    const year = new Date().getFullYear()
    const month = new Date().getMonth() - 1
    start = moment(new Date(year, month, 1)).format('YYYYMMDD')
    end = moment(new Date(year, month + 1, 0)).format('YYYYMMDD')
  } else if (interval === 'last7days') {
    start = moment(new Date().setDate(new Date().getDate() - 7)).format(
      'YYYYMMDD',
    )
    end = moment(new Date().setDate(new Date().getDate() - 1)).format(
      'YYYYMMDD',
    )
  } else if(interval === 'last30days') {
    start = moment(new Date().setDate(new Date().getDate() - 30)).format(
      'YYYYMMDD',
    )
    end = moment(new Date().setDate(new Date().getDate() - 1)).format(
      'YYYYMMDD',
    )
  }
  return { start, end }
}

export const getRealDateRange = (interval) => {
  let start, end
  if(interval === 'lastweek') {
    start = moment().day(-7).format('YYYYMMDD')
    end =  moment().day(-1).format('YYYYMMDD')
  } else if(interval === 'lastmonth') {
    const year = new Date().getFullYear()
    const month = new Date().getMonth() - 1
    start = moment(new Date(year, month, 1)).format('YYYYMMDD')
    end = moment(new Date(year, month + 1, 0)).format('YYYYMMDD')
  } else if (interval === 'last7days') {
    start = moment().subtract(6, 'days').format(
      'YYYYMMDD',
    )
    end = moment().format(
      'YYYYMMDD',
    )
  } else if(interval === 'last30days') {
    start = moment().subtract(29,'days').format(
      'YYYYMMDD',
    )
    end = moment().format(
      'YYYYMMDD',
    )
  }
  return { start, end }
}

export const getDateList = (interval, dateRange) => {
  let res = []
  if (interval === 'last30days') {
    for (let i = 31; i >= 1; i--) {
      const now = new Date()
      now.setDate(now.getDate() - i)
      res.push({
        num: moment(now).format('YYYY/MM/DD'),
        date: moment(now).format('YYYYMMDD'),
      })
    }
  } else if (interval === 'lastmonth') {
    const year = new Date().getFullYear()
    const month = new Date().getMonth() - 1
    const lastDay = new Date(year, month + 1, 0)
    for (let i = 1; i <= lastDay.getDate(); i++) {
      res.push({
        num: moment(new Date(year, month, i)).format('YYYY/MM/DD'),
        date: moment(new Date(year, month, i)).format('YYYYMMDD'),
      })
    }
  } else {
    const lastDate = moment(dateRange.end)
    const firstDate = moment(dateRange.start)
    const date = new Date(firstDate)
    res.push({
      num: firstDate.format('YYYY/MM/DD'),
      date: moment(firstDate).format('YYYYMMDD'),
    })
    while (
      date.getDate() !== lastDate.date() ||
      date.getMonth() !== lastDate.month()
    ) {
      date.setDate(date.getDate() + 1)
      res.push({
        num: moment(date).format('YYYY/MM/DD'),
        date: moment(date).format('YYYYMMDD'),
      })
    }
    console.log(res)
    // console.log(new Date(firstDate))
    // console.log(moment(dateRange.start).date())
  }
  return res
}

export const disabledDate = (current, start, real = false) => {
  return current && ((real ? current > moment().endOf('date') : current >= moment().startOf('date')) || current < moment(start).startOf('date'))
}
export const disabledMonth = (current, start) => current && (current >= moment().startOf('month') || current < moment(start).startOf('month'))

export const transformData2Excel = (data) => {
  const dates = [...new Set(data.map(item => moment(item.date).format('YYYYMMDD')))]
  if(!dates.length) return []
  return dates.map(date => data.filter(item => item.date === date).map(item => ({
      date: item.date,
      [item.hour]: item.val
    })).reduce((prev, next) => Object.assign(prev,next), {})
  )
}

// const mobile = 'https://m.learn.dev.longan.eliteu.xyz'
// const pc = 'https://learn.dev.longan.eliteu.xyz'

export const targetURL = 'https://learn.dev.longan.eliteu.xyz'


// 判断是否是移动端
export const isMobile = () => {
  try {
    // 用户信息
    const userAgent = window.navigator.userAgent.toLowerCase()
    return /iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent)
  } catch (e) {
    console.log('isMobile判断失败')
    return false
  }
}