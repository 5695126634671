import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useCallback
} from 'react';
import {
  Input,
  Form,
  Modal,
  Select,
  InputNumber,
  Transfer,
  message,
  Spin
} from 'antd';
import '../style.scss';
import { useSelector, useDispatch } from 'react-redux';
import { modifyEmployee, checkTeamCourse } from './../../../../utils/api/manageCenter';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { getUser } from './../../../../store/actions';
import { DownOutlined, ExclamationCircleFilled } from '@ant-design/icons';

const ModifyEmployee = (props, ref) => {
  const userInfo = useSelector((state) => state.userInfo)
  const history = useHistory();
  const orgNum = useSelector(state => state.orgNum);
  const [modifyVisible, setModifyVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const formRef = useRef();
  const [showNumber, setShowNumber] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [targetKeys, setTargetKeys] = useState([]);
  const [targetCourse, setTargetCourse] = useState([]);
  const [targetCourseBag, setTargetCourseBag] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [courseErr, setCourseErr] = useState();
  const [courseBagErr, setCourseBagErr] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [allowCourseBag, setAllowCourseBag] = useState(false);
  const dispatch = useDispatch();
  const [minNum, setMinNum] = useState(null);
  const [numObj, setNumObj] = useState(null);
  const [nowStep, setNowStep] = useState(0);
  const [curEmployee, setCurEmployee] = useState({
    name:''
  });
  let curEmployeeCopy = null
  const [checkCourseList, setCheckCourseList] = useState([]);
  useEffect(() => {
    if (props.modifyItem && modifyVisible) {
      const modifyItem = JSON.parse(JSON.stringify(props.modifyItem));
      const { student_nums, team_nums, studio_nums, is_manager } = modifyItem;
      const student_num_res = student_nums.includes('all') ? 0 : student_nums.split('/')[1]
      const team_nums_res = team_nums.includes('all') ? 0 : team_nums.split('/')[1]
      const studio_nums_res = studio_nums.includes('all') ? 0 : studio_nums.split('/')[1]
      if(formRef.current){
        formRef.current.setFieldsValue({
          username: modifyItem.username,
          phone: modifyItem.phone,
          name: modifyItem.name,
          is_full: JSON.stringify(modifyItem.is_full),
          student_num: student_num_res,
          team_num: team_nums_res,
          sample_reels: studio_nums_res
        })
      }
      const courseIdMap = new Map(props.allCourse.map(c => [c.id.toString(), true]));
      const courseIds = modifyItem.course
        .filter(f => courseIdMap.has(f.toString()))
        .map(Number);
      setNumObj({
        student_num: student_num_res,
        team_num: team_nums_res,
        sample_reels: studio_nums_res
      });
      let filterData = props.allTeam.map(item => {
        if (item.team_teacher.length >= 3) {
          item.disabled = true;
          modifyItem.team.forEach(el => {
            if (item.id === el.id) {
              item.disabled = false;
            }
          });
        }
        return item;
      });
      setDataSource(filterData);
      let targetDataId = modifyItem.team.map(element => {
        delete element.name;
        return element.id;
      });
      setIsManager(is_manager)
      setTargetKeys(targetDataId);
      setTargetCourse(courseIds)
      const allowCourseBag = userInfo.organization_data.allow_course_bag
      setAllowCourseBag(allowCourseBag)
      setExpanded(is_manager || (allowCourseBag && courseIds.length > 0) || !allowCourseBag)
      setTargetCourseBag(modifyItem.course_bag)
      setMinNum({
        teacher_student_nums: modifyItem.teacher_student_nums,
        teacher_studio_nums: modifyItem.teacher_studio_nums,
        teacher_team_nums: modifyItem.teacher_team_nums
      });
      setShowNumber(!modifyItem.is_full);
    }
    // eslint-disable-next-line
  }, [formRef, props.modifyItem, modifyVisible, props.allTeam, props.allCourse]);
  const filterOption = (inputValue, option) => {
    return option.name.indexOf(inputValue) > -1;
  };

  const handleChange = targetKeys => {
    setCourseErr(null)
    setCourseBagErr(null)
    setTargetKeys(targetKeys);
  };

  const handleSelectCourse = targetKeys => {
    setCourseErr(null)
    setTargetCourse(targetKeys);
  };
  const handleSelectCourseBag = targetKeys => {
    setCourseBagErr(null)
    setTargetCourseBag(targetKeys);
  };


  useImperativeHandle(ref, () => ({
    modifyEmployeeHandler: () => setModifyVisible(true),
    loadingEmployee: (val) =>setLoading(val)
  }));

  const handleSubmit = () => {
    if (nowStep === 0) {
      formRef.current.submit()
    } else {
      fetchCreate()
    }
  }
  const fetchCreate = useCallback(() => {
    let params = JSON.parse(JSON.stringify({...curEmployee,...curEmployeeCopy}))
    params.team_list = targetKeys;
    if (allowCourseBag) {
      params.courses = targetCourse;
    } else {
      params.courses = [...targetCourse, ...checkCourseList.map(m => m.id)];
    }
    params.course_bags = targetCourseBag
    params.id = props.modifyItem.id;
    if (isManager) {
      params.is_full = props.modifyItem.is_full;
    } else {
      params.is_full = JSON.parse(params.is_full)
    }
    modifyEmployee(params)
      .then(res => {
        message.success('修改员工信息成功');
        setShowNumber(false);
        setModifyVisible(false);
        setCourseErr(null)
        setCourseBagErr(null)
        // eslint-disable-next-line
        curEmployeeCopy = null
        setTimeout(() => {
          history.replace('/manageCenter');
          dispatch(getUser());
        }, 200);
      })
      .catch(e => {
        if (typeof e.response.data === 'string') return;
        if (e.response.status === 400) {
          const messageStr = e.response.data.message;
          if (messageStr.includes('分配的课程')) {
            setCourseErr({
              validateStatus: "error",
              help: messageStr
            })
          } else if(messageStr.includes('课程包')){
            setCourseBagErr({
              validateStatus: "error",
              help: messageStr
            })
          } else{
            message.warning(messageStr);
          }
        } else {
          message.error('修改员工信息失败');
        }
      });
      // eslint-disable-next-line
  },[curEmployee, props, checkCourseList, targetCourse, targetCourseBag, targetKeys, allowCourseBag])
  // 校验成功调用接口
  const fetch = values => {
      setCurEmployee(values)
      curEmployeeCopy = values
    if (!isManager) {
      checkTeamCourse({ courses: targetCourse, team_list: targetKeys }).then(res => {
        setCheckCourseList(res.data.course)
        setNowStep(1)
      })
    } else {
      setTimeout(() => {
        fetchCreate()
      }, 100);
    }
  };

  const textRegExp = text => {
    return /^[A-Za-z0-9_\u4e00-\u9fa5]+$/.test(text);
  };
  // 剩余数：
  const maxStudent = Number(orgNum?.remaining_students) + Number(numObj?.student_num);
  const maxTeam = Number(orgNum?.remaining_team) + Number(numObj?.team_num);
  const maxPortfolio = Number(orgNum?.remaining_portfolio) + Number(numObj?.sample_reels);
  return (
    <Modal
      title="修改教师信息"
      width={1000}
      centered={true}
      getContainer={false}
      closeIcon={<img src="/image/close.png" alt="close" width="14px" height="14px" />}
      className={classNames('createModal', 'modifyModal')}
      destroyOnClose={true}
      visible={modifyVisible}
      onCancel={() => {
        setShowNumber(false);
        setModifyVisible(false);
        setCourseErr(null)
        setCourseBagErr(null)
        setNowStep(0)
      }}
      okText={nowStep === 0 && !isManager ? '下一步' : "保存"}
      onOk={handleSubmit}
      maskClosable={false}
    >
      <Spin spinning={loading}>
        <div className="content2">
          {
            nowStep === 0
              ? <Form
                name="modify"
                ref={formRef}
                onFinish={values => {
                  fetch(values);
                }}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  label="登录用户名"
                  name="username"
                  rules={[{ required: true, message: '请输入登录用户名' }]}
                >
                  <Input disabled maxLength="20" placeholder="请输入" />
                </Form.Item>
                <Form.Item
                  label="手机号"
                  name="phone"
                  rules={[{ required: true, message: '请输入手机号' }]}
                >
                  <Input disabled placeholder="请输入" />
                </Form.Item>
                <Form.Item
                  label="姓名"
                  name="name"
                  rules={[
                    { required: true, message: '请输入姓名' },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || (value && textRegExp(value))) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          '姓名支持输入中文、英文大小写、下划线、数字'
                        );
                      }
                    })
                  ]}
                >
                  <Input placeholder="请输入" maxLength="20" />
                </Form.Item>
                <Form.Item
                  label="分配使用量"
                  name="is_full"
                  rules={[{ required: true, message: '请选择分配使用量' }]}
                >
                  <Select
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="请选择"
                    onChange={value => {
                      setShowNumber(`${value}` === 'false');
                    }}
                    getPopupContainer={triggerNode =>
                      triggerNode.parentElement || document.body
                    }
                    disabled={isManager}
                  >
                    <Select.Option value="true" label="和其他教师共享全校区的使用量">
                      和其他教师共享全校区的使用量
                    </Select.Option>
                    <Select.Option value="false" label="分配专属额度">
                      分配专属额度
                    </Select.Option>
                  </Select>
                </Form.Item>
                <div className='next_tips'>
                  {
                    isManager ? '校区管理员默认和其他教师共享全校区的使用量，无需分配。' : <>对于正式编制的教师，可以选择「和其他教师共享全校区的使用量」，这样既能避免浪费，也方便管理。<br></br>对于临时教师，建议选择「分配专属额度」，虽然会导致校区的部分使用量被预先冻结，但可以避免此教师失误用完所有额度而影响全校区的使用。</>
                  }
                </div>
                {showNumber ? (
                  <div>
                    <Form.Item
                      label={
                        <>
                          可创建学员数量
                          <span className="help">
                            {
                              maxStudent === 0 ? '（校区剩余额度不足以给此教师分配专属额度）' : <>（可分配的学员数量为 {minNum.teacher_student_nums === maxStudent ? minNum.teacher_student_nums : `${minNum.teacher_student_nums}～${maxStudent}`}）</>
                            }
                          </span>
                        </>
                      }
                      name="student_num"
                      rules={[
                        { required: true, message: '请输入学员数量' },
                        ({ getFieldValue }) => ({
                          validator(rule, val) {
                            let value = String(val);
                            if (maxStudent === 0 && value) {
                              return Promise.reject('校区剩余额度不足以给此教师分配专属额度，请选择「和其他教师共享全校区的使用量」')
                            } else if (
                              !value ||
                              value === 'null' ||
                              (value &&
                                value >= minNum?.teacher_student_nums &&
                                value <= maxStudent)
                            ) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(`考虑到此教师已经创建的学员（基数）和校区剩余额度（可扩展的数量），可分配的学员数量为 ${minNum.teacher_student_nums === maxStudent ? minNum.teacher_student_nums : minNum.teacher_student_nums + '～' + maxStudent}`);
                            }
                          }
                        })
                      ]}
                    >
                      <InputNumber
                        placeholder="请输入"
                      />
                    </Form.Item>
                    <Form.Item
                      label={
                        <>
                          可创建团队数量
                          <span className="help">
                            {
                              maxTeam === 0 ? '（校区剩余额度不足以给此教师分配专属额度）' : <>
                                （可分配的团队数量为 {minNum.teacher_team_nums === maxTeam ? minNum.teacher_team_nums : `${minNum.teacher_team_nums}～${maxTeam}`}）</>
                            }

                          </span>
                        </>
                      }
                      name="team_num"
                      rules={[
                        { required: true, message: '请输入团队数量' },
                        ({ getFieldValue }) => ({
                          validator(rule, val) {
                            let value = String(val);
                            if (maxTeam === 0 && value) {
                              return Promise.reject('校区剩余额度不足以给此教师分配专属额度，请选择「和其他教师共享全校区的使用量」')
                            } else if (
                              !value ||
                              value === 'null' ||
                              (value &&
                                value >= minNum?.teacher_team_nums &&
                                value <= maxTeam)
                            ) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(`考虑到此教师已经创建的团队（基数）和校区剩余额度（可扩展的数量），可分配的团队数量为 ${minNum.teacher_team_nums === maxTeam ? minNum.teacher_team_nums : minNum.teacher_team_nums + '～' + maxTeam}`);

                            }
                          }
                        })
                      ]}
                    >
                      <InputNumber placeholder="请输入" />
                    </Form.Item>
                    <Form.Item
                      label={
                        <>
                          可创建作品集数量
                          <span className="help">
                            {
                              maxPortfolio === 0 ? '（校区剩余额度不足以给此教师分配专属额度）' : <>
                                （可分配的作品集数量为 {minNum.teacher_studio_nums === maxPortfolio ? minNum.teacher_studio_nums : `${minNum.teacher_studio_nums}～${maxPortfolio}`}）</>
                            }
                          </span>
                        </>
                      }
                      name="sample_reels"
                      rules={[
                        { required: true, message: '请输入作品集数量' },
                        ({ getFieldValue }) => ({
                          validator(rule, val) {
                            let value = String(val);
                            if (maxPortfolio === 0 && value) {
                              return Promise.reject('校区剩余额度不足以给此教师分配专属额度，请选择「和其他教师共享全校区的使用量」')
                            } else if (
                              !value ||
                              value === 'null' ||
                              (value &&
                                value >= minNum?.teacher_studio_nums &&
                                value <= maxPortfolio)
                            ) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(`考虑到此教师已经创建的作品集（基数）和校区剩余额度（可扩展的数量），可分配的作品集数量为 ${minNum.teacher_studio_nums === maxPortfolio ? minNum.teacher_studio_nums : minNum.teacher_studio_nums + '～' + maxPortfolio}`);
                            }
                          }
                        })
                      ]}
                    >
                      <InputNumber placeholder="请输入" />
                    </Form.Item>
                  </div>
                ) : null}
                <Form.Item label="分配团队" name="team_list" className='courses-form-item'>
                  <>
                    <Transfer
                      dataSource={dataSource}
                      showSearch
                      filterOption={filterOption}
                      targetKeys={targetKeys}
                      onChange={handleChange}
                      render={item => <span title={item.name}>{item.name}</span>}
                      rowKey={record => record.id}
                      listStyle={{
                        width: '47.5%',
                        height: '500px'
                      }}
                    />
                    <div className='tips'>当教师关联上某个团队时，也会自动获得此团队关联的全部课程。</div>
                  </>
                </Form.Item>
                {
                  userInfo.user_type.includes('manager') && !isManager && allowCourseBag && <Form.Item label="给教师关联课程包，并接受上级主管部门直接动态分配课程：" name="courses" {...courseBagErr} className='courses-form-item'>
                    <>
                      <Transfer
                        titles={['校区全部课程包', '此教师可用课程包']}
                        dataSource={props.allCourseBag}
                        showSearch
                        filterOption={(inputValue, option) => {
                          return option.title.indexOf(inputValue) > -1;
                        }}
                        targetKeys={targetCourseBag}
                        onChange={handleSelectCourseBag}
                        render={item => <span title={item.title}>{item.title}</span>}
                        rowKey={record => record.id}
                        listStyle={{
                          width: '47.5%',
                          height: '500px'
                        }}
                      />
                    </>
                  </Form.Item>
                }
                {
                  <Form.Item style={{ height: expanded ? 'auto' : '20px' }} label={allowCourseBag && !isManager ? <span onClick={() => setExpanded(true)} style={{ color: expanded ? '#1e1e1e' : '#0084FF', cursor: expanded ? 'auto' : 'pointer' }}>从校区的全部课程权限中，给教师分配固定的课程{expanded ? '：' : <DownOutlined style={{ marginLeft: '5px', fontSize: '14px' }} />}</span> : "分配课程"} name="courses" {...courseErr} className='courses-form-item'>
                    {
                      ((allowCourseBag && expanded) || !allowCourseBag) && <>
                        {
                          !isManager &&
                          <Transfer
                            titles={['校区全部可见课程', '此教师可见课程']}
                            dataSource={props.allCourse}
                            showSearch
                            filterOption={(inputValue, option) => {
                              return option.title.indexOf(inputValue) > -1;
                            }}
                            targetKeys={targetCourse}
                            onChange={handleSelectCourse}
                            render={item => <span title={item.title}>{item.title}</span>}
                            rowKey={record => record.id}
                            listStyle={{
                              width: '47.5%',
                              height: '500px'
                            }}
                          />
                        }
                        {
                          courseErr?.validateStatus !== 'error' ?
                            (isManager ? <div className='course_tip'>
                              管理员拥有校区的全部课程，无需分配。
                            </div> : <div className='tips'>
                              {allowCourseBag ? "给教师分配固定课程一般用于在不方便给当前教师分配一整个课程包的权限时，额外给他分配几节课的权限。" : "分配课程时，建议和此教师管理的团队对应。"}
                            </div>)
                            : null
                        }

                      </>
                    }
                  </Form.Item>
                }
              </Form>
              : <div className='course_check'>
                {checkCourseList.length === 0 ?
                  <div className='tips pass'>
                    经过校验，你给{curEmployee.name}分配的资源权限符合规范，点击【完成】即给此教职工赋予教师身份。
                  </div> :
                  <div>
                    <p className="flex-tip">
                      {
                        allowCourseBag ? <><ExclamationCircleFilled style={{ fontSize: '16px', color: '#faad14', marginRight: '7px', marginTop: '3px' }} /><span>提示：你给{curEmployee.name}分配的团队中有正在进行授课的团队，下方呈现的课程清单是你没有给{curEmployee.name}分配使用权限但是团队需要的。</span></> : <p>你给{curEmployee.name}分配的团队已绑定部分课程，其中以下课程是你未给此教师分配的。</p>
                      }
                    </p>
                    <div className='course_list'>
                      {checkCourseList.map(m => <p key={m.id}>{m.title}</p>)}
                    </div>
                    <div className='tips'>
                      {
                        allowCourseBag ? <>
                          为保证教学工作的正常开展，点击【完成】，{curEmployee.name}将会临时获得上述全部课程的使用权限。当将{curEmployee.name}不再是相关团队的授课教师时，由这些临时赋予给他的权限也会被同步收回。<br></br>
                          若你还是不希望{curEmployee.name}能浏览上述课程，请点击【上一步】，调整分配给此教师的团队名单。
                        </> : <>为保证教学工作的正常开展，点击【完成】，则自动给此教师赋予上述课程的浏览权限。<br></br>
                          若不希望此教师浏览上述课程，请点击【上一步】，调整分配给此教师的团队名单。</>
                      }
                    </div>
                  </div>
                }
              </div>
          }

        </div>
      </Spin>
    </Modal>
  );
};

export default forwardRef(ModifyEmployee);
